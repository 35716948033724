<template>
  <div class="app-layout">
    <header class="app-header">
      <router-link to="/">
        <img src="@/assets/images/stonely-logo.svg" alt="Stonely logo" />
      </router-link>
    </header>
    <div class="schedule-measurement">
      <img src="@/assets/images/check-mark.svg" alt="" />

      <p class="my-3">{{ $t("createOrder.scheduleMeasurement.successMessage") }}</p>
      <p class="mb-md-4 mb-6">{{ $t("createOrder.scheduleMeasurement.actionDescription") }}</p>

      <v-btn
        large
        depressed
        color="primary"
        class="rounded-0 mx-auto"
        :href="calendlyAppointmentURL"
      >
        {{ $t("createOrder.scheduleMeasurement.action") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Customer", {
      fullName: state => state.fullName,
      emailAddress: state => state.emailAddress
    }),
    calendlyAppointmentURL() {
      const queryString = qs.stringify({
        name: this.fullName,
        email: this.emailAddress
      });
      return `${process.env.VUE_APP_CALENDLY_APPOINTMENT_URL}?${queryString}`;
    }
  }
};
</script>